import React from "react";
import styles from "../styles/badge.module.scss";

export default function Badge(props) {
  return (
    <div className={ `${ styles.badge } label` }>
      { props.icon ? <div className={ `icon__left` }> { props.icon } </div> : '' }
      { props.text }
    </div>
  );
};