import React from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import HomeIntro from "../components/home/intro";
import HomeTech from "../components/home/tech";

export default function Index() {
  function scrollToTech() {
    scrollTo('#tech');
  }
  
  return (
    <div>
      <HomeIntro onScrollToTech={() => scrollToTech()}/>
      <hr className={ `divider__orange` } id="tech"/>
      <HomeTech/>
    </div>
  )
};
